// font size
$font-xxsm: 0.75rem;
$font-xsm: 0.875rem;
$font-sm: 1rem;
$font-md: 1.125rem;
$font-lg: 1.5rem;
$font-xl: 3rem;
$font-xxl: 5rem;
$font-xxlg: 6.75rem;

// screen size 
$sc-xsm: 375px;
$sc-sm: 544px;
$sc-md: 768px;
$sc-lg: 1012px;
$sc-xsm: 1280px;

// color 
$black: #000;
$off-black: #1d1d1f;
$off-black2:#282c34;
$gray: #374151;
$light-gray: #f5f5f7;
$solid-gray: #494949;
$white: #fff;
$off-white: #d2d2d7;
$off-white2: #fbfbfd;
$link: #0001ee;

// notification colors
$danger: #a00;