@import "./variable";

* {
  -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: logical 0;
    -webkit-tap-highlight-color: transparent;
    
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $off-black2;
background-color: $off-white2;
}

p {
  color: $solid-gray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
  border-radius: 10px;
  padding: 12px 17px;
  border: 01px solid $off-white;
  color: $off-black2;
  width: 100%;
  font-size: 0.895rem;
}

.button-corner {
  border-radius: 30px;
  background-color: $off-black2;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 30px;
  border: none;
  font-size: 0.89rem;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid #d2d2d7;
  white-space: nowrap;
}

.button {
  border-radius: 30px;
  background-color: $off-black2;
  color: #fff;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 30px;
  border: none;
  font-size: 0.89rem;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
}
.cta-wrapper {
  padding-bottom: 3rem;
  padding-top: 2rem;
}

a.forgot-password {
  display: block;
  text-decoration: none;
  padding-bottom: 1rem;
}

.signin-link {
  cursor: pointer;
  color: $link;
}