@import '../../styles/variable';

// HEADER
.App-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1rem;
}

.App-title {
  font-size: 1.2rem;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  color: unset;
}

.App {
  &-logo {
    svg {
      width: 80px;
      height: 80px;
    }
  }
}

// FOOTER
.footer-wrapper {
  background-color: $off-white2;
  padding: 1.2rem;
  @media (min-width: 768px) {
    display: flex;
    justify-content: space-between;
  }
}

.footer-text {
  font-size: 13px;
  padding-bottom: 0.3rem;
}

.error {
  &__boundaryContainer {
    max-width: 360px;
    margin: 0 auto;
  }

  &__boundary {
    max-width: 321px;
    width: 100%;
    margin: 0 auto;
    padding: 1rem;
    border-radius: 10px;
    // box-shadow: rgb(0 0 0 / 16%) 0px 1px 2.5px 0px;
    background-color: $white;
  }
}
