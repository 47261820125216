@import '../../styles/variable';

.App {
  min-height: 100vh;
  background-color: #ffffff;
  color: #282c34;
  padding: 0.1rem 1.2rem;
  overflow-x: hidden;
}

.main {
  .hero {
    &Container {
      margin-bottom: 2rem;
    }
    &_imageContainer {
      margin-right: 3px;
      margin-left: 3px;
    }

    &_imageContainer.item-down {
      // background-color: red;
      margin-top: 150px;
    }

    &_image {
      margin-bottom: 6px;
      width: 110px;
      height: 157px;
      border-radius: 25px;
      overflow: hidden;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media (min-width: 768px) {
    padding-top: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .subscribers {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    &__form {
      max-width: 487px;
    }
  }
}

.landing-page,
.signup-page,
.signin-page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.getstarted {
  &-name {
    font-size: 2rem;
    font-weight: 600;
    margin-left: 1rem;
  }
  form {
    display: flex;
    align-items: center;
    border-radius: 5rem 5rem;
    border: 1px solid $off-white;
    padding: 0.4rem;

    div > input:focus-visible {
      outline: none;
    }

    div > input {
      border: none;
      padding-left: 0px;
      padding-top: 0px;
      padding-bottom: 0px;
      font-size: 2rem;
      font-weight: 600;
    }

    button {
      padding: 17px 3rem;
      font-size: 1rem;
    }
  }
}

.hero-wrapper {
  padding-bottom: 1rem;
}

.hero-text {
  font-size: $font-xxl;
  @media (min-width: 768px) {
    font-size: $font-xxlg;
  }
}

.buttons-wrapper {
  padding-bottom: 2rem;
}

.buttons-wrapper .button-corner {
  margin: 0 0.5rem;
}

.caption-text {
  font-size: $font-lg;
  @media (min-width: 768px) {
    font-size: $font-xl;
    max-width: 483px;
  }
}
