@import "../../../styles/variable";

.input-wrapper {
    padding: 0.3rem 0;

    input {
        display: block;
    }

    &-child {
        text-align: left;
        font-size: $font-xxsm;
        margin: 0.5rem;
        color: $danger;
    }
}